import React from 'react';
import { BrowserRouter as Router, Route,Switch, useParams} from 'react-router-dom';


import App from './App';
import Hakkimizda from './moduller/Hakkimizda/Hakkimizda'
import S9 from './moduller/9';
import S10 from './moduller/10';
import S11 from './moduller/11';
import S12 from './moduller/12';
import Anasayfa from "./moduller/Anasayfa/Anasayfa";
import Giris from './moduller/Giris/Giris';
import ScrollTop from './componentler/ScrollTop';
import S404 from './moduller/404/404';

const CustomRoute = () =>{
    return ( 
    <Router>
        <ScrollTop>
                <Route path="/" component={App}/>
        <Switch>
                <Route exact path="/Anasayfa" component={Anasayfa}/>
                <Route path="/Giris" component={Giris}/>
                <Route path="/9" to="9" component={S9}/>
                <Route path="/10" component={S10}/>
                <Route path="/11" component={S11}/>
                <Route path="/12" component={S12}/>
                <Route path="/Hakkimizda" component={Hakkimizda}/>
                <Route path="*" component={S404}/>
        </Switch>

        </ScrollTop>
    </Router>
    )} 

export default React.memo(CustomRoute);



function Child() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams();
    return (
      <div>
        { /*<h3>ID: {id}</h3> */}
      </div>
    );
  }
  