import React from 'react'
import Menu from 'antd/lib/menu';
import "antd/dist/antd.css";
//import { MailOutlined, DatabaseTwoTone, HomeTwoTone, TagTwoTone } from '@ant-design/icons';

const { SubMenu } = Menu;

class MenuTop extends React.Component {
  state = {
    current: 'anasayfa',
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    /*const aciklama = this.props.aciklama.map((r)=>
    (r.yenimi) ? <li>{r.ad}    <Badge variant="danger">Yeni</Badge></li> : <li>{r.ad}</li>
    );*/ 
    return (
      <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal" style={{backgroundColor:'#60FF7470',textAlign:'center'}}>
        {this.props.aciklama.map((r)=>
            <Menu.Item key={r.menuKey} icon={r.menuIcon}>
            {r.menuAdi}
            </Menu.Item>
        )}
      </Menu>
    );
  }
}
export default MenuTop;