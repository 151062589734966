import React, {useEffect, useState} from 'react'
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import SinifCard from '../componentler/SinifCard';
import axios from "axios";

/*
class S9 extends React.Component{
        render(){ 
        return (
            <div className="App">
                <Row justify="center">
                    <Col><SinifCard title="Temel Mesleki Uygulamalar" aciklama={[{yenimi:false,ad:'Yıllık planlar'}, {yenimi:false,ad:'Ders sunumları'},{yenimi:true,ad:'Alıştırmalar'}]}/></Col>
                    <Col><SinifCard title="Anatomi ve Fizyoloji" aciklama={[{yenimi:true,ad:'Yıllık planlar'}, {yenimi:false,ad:'Ders sunumları'},{yenimi:true,ad:'Alıştırmalar'}]}/></Col>
                    <Col><SinifCard title="Mesleki Gelişim Atölyesi" aciklama={[{yenimi:'true',ad:'Yıllık planlar'}, {yenimi:false,ad:'Ders sunumları'},{yenimi:false,ad:'Alıştırmalar'}]}/></Col>
                </Row>
            </div>
        )
    }
}
export default React.memo(S9);
*/

function S9 () {

    const [userData,setUserData] = useState([]);


    useEffect(()=>{
        axios.get('https://www.saglikhizmetleri.gen.tr/sapi/dersler/TumDersler/9')
            .then(response => {
                setUserData(response.data)
            }).catch(error => {
                console.log(error);
            })
    },[]);


    const listItems = userData.map((number,key) =>
                <Col key={key}><SinifCard title={number.DERSADI} aciklama={[{yenimi:false,ad:'Yıllık planlar'}, {yenimi:false,ad:'Ders sunumları'},{yenimi:true,ad:'Alıştırmalar'}]}/></Col>
    );

    return (
        <div className="App">
        <Row justify="center">
            {listItems}
        </Row>
    </div>
    )
}

export default React.memo(S9);
