import React from 'react';
import { Link } from 'react-router-dom'
import Card from "antd/lib/card";
import "antd/dist/antd.css";

class Duyuru extends React.Component{     
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div style={{marginTop:'2rem',marginRight:'1rem', marginLeft:'1rem', display:'grid'}} >
                <div style={{borderRadius: '2rem'}}>
                <Link to={this.props.sinif}>
                    <Card
                        //onClick={this.handleClick}
                        actions={[]}
                        title={this.props.title}
                        hoverable = 'true'
                        style={{width: '18rem',borderRadius:'2rem', margin: '1rem', backgroundColor:'#51F0AC'}}
                        cover={<img alt="görsel" src={this.props.resim} style={{width:'18rem', height:'20rem'}}/>}
                        headStyle={{borderTopLeftRadius:'2rem', borderTopRightRadius: '2rem', backgroundColor: 'rgba(255, 255, 255, 0.4)', border: 0, textAlign:'center', height:'3rem'}}
                        bodyStyle={{border: 0, margin:'1rem', verticalAlign:'top',color:'#A33A27', fontWeight:'bold' }}
                    >
                        <p>Biz sağlık hizmetleri alanında çalışan öğretmenleriz.</p>
                    </Card>
                </Link>
                </div>
            </div>
        )
      }


}

export default Duyuru;
