import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
//import { notification, Form, Input, Button, Checkbox } from 'antd';
import notification from 'antd/lib/notification';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from "axios";
import { Route, Redirect } from 'react-router-dom';


function Giris() {
        const [kullanici,setKullaniciAdi] = useState('');
        const [kullaniciSoyad,setkullaniciSoyad] = useState('');
        const [bilgi,setBilgi] = useState(false);

/*
    const onFinish = values => {
        //console.log('Received values of form: ', values);
        if(bilgi == true)
        {
             notification.success({
                 style:{backgroundColor:'greenyellow'},
                 message: `Başarılı bir şekilde giriş yaptınız.`,
                 description:
                     'Sitemizde iyi vakit geçirmenizi dileriz. :)',
                 placement:'topRight',
                 });
        } 
        else 
        {
            notification.error({
                 style:{backgroundColor:'aquamarine'},
                 message: `Hata`,
                 description:
                     'Kulanıcı adı ve/veya şifrenizi giriniz.',
                 placement:'topRight',
            });
        }
 
    };
*/
    async function fetchData() {
        axios.post('https://www.saglikhizmetleri.gen.tr/sapi/uyeler/uyeKontrol',[username,password])
        .then(response => {
            //console.log(response.data);
            BildirimGoster(response.data.STATUS);
            setBilgi(response.data.STATUS);
            setKullaniciAdi(response.data.MESSAGE.AD)
            setkullaniciSoyad(response.data.MESSAGE.SOYAD);
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(()=>{
        //fetchData()
    },[]);


    const handleSubmit = e => {
        e.preventDefault()
        fetchData()
    }

   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');

    const handleChange = event => {
        event.preventDefault();
        //console.log(event.target.name);
        //console.log(event.target.value);
        //setUserInfo({userInfo,[event.target.name]: event.target.value});
        if(event.target.name == "username") {
            //console.log(event.target.value);
            setUsername(event.target.value);
        }
        if(event.target.name == "password") {
            setPassword(event.target.value);
        }
        //console.log(userInfo);
    }
/*
   const handeleEvent = e =>{
       //e.preventDefault();
       if(kullanici != '' && sifre != '')
       {
            setKullaniciAdi('');
            setSifre('');
            notification.success({
                style:{backgroundColor:'greenyellow'},
                message: `Başarılı bir şekilde giriş yaptınız.`,
                description:
                    'Sitemizde iyi vakit geçirmenizi dileriz. :)',
                placement:'topRight',
                });
       } 
       else 
       {
           setKullaniciAdi('');
           setSifre('');
           notification.error({
                style:{backgroundColor:'aquamarine'},
                message: `Hata`,
                description:
                    'Kulanıcı adı ve/veya şifrenizi giriniz.',
                placement:'topRight',
           });
       }
   }
*/
      return (
          bilgi
            ? 
                //<div style={{width:'25rem', display:'block', marginLeft:'auto', marginRight:'auto',marginTop:'5rem',backgroundColor:'#60FF7470',padding:'2rem',borderRadius:'2rem'}}>
                <div>
                    Merhaba {kullanici} {kullaniciSoyad}  
                </div>
            : 
            <div style={{width:'25rem', display:'block', marginLeft:'auto', marginRight:'auto',marginTop:'5rem',backgroundColor:'#60FF7470',padding:'2rem',borderRadius:'2rem'}}>
                    <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                    remember: true,
                    }}
                    //onFinish={onFinish}
                    >
                    <Form.Item
                        hasFeedback="true"
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: 'Kullanıcı adınızı giriniz!',
                        },
                        ]}
                    >
                        <Input 
                        style={{borderRadius:'0.5rem'}}
                        onChange= {handleChange}
                        onBlur= {handleChange}
                        prefix={<UserOutlined className="site-form-item-icon" />} 
                        name="username"
                        placeholder="Kullanıcı Adı" 
                        />
                    </Form.Item>
                    <Form.Item
                        hasFeedback="true"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Şifrenizi giriniz!',
                        },
                        ]}
                    >
                        <Input
                        style={{borderRadius:'0.5rem'}}
                        onBlur= {handleChange}
                        onChange= {handleChange}
                        allowClear="true"
                        name="password"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Şifre"
                        value=""
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Beni hatırla</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                        Şifremi unuttum.
                        </a>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" 
                        htmlType="submit" 
                        onClick={handleSubmit} 
                        className="login-form-button" 
                        shape="round">
                        Giriş
                        </Button>
                        veya <a href="">Kayıt ol!</a>
                    </Form.Item>
                    </Form>
            </div>
        );
}
export default React.memo(Giris);


function BildirimGoster(bilgi) {
    if(bilgi == true)
    {
         notification.success({
             style:{backgroundColor:'greenyellow',borderRadius:'1rem'},
             message: `Başarılı bir şekilde giriş yaptınız.`,
             description:
                 'Sitemizde iyi vakit geçirmenizi dileriz. :)',
             placement:'topRight',
             });
    } 
    else 
    {
        notification.error({
             style:{backgroundColor:'#A6948D',borderRadius:'1rem'},
             message: `Hata`,
             description:
                 'Kulanıcı adı ve/veya şifreniz yanlıştır.',
             placement:'topRight',
        });
    }

}