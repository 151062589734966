import React from 'react'
import ReactCardFlip from 'react-card-flip'
//import { Button, Card, Badge, List, Row } from "antd";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Badge from "antd/lib/badge";
//import List from "antd/lib/list";
import {List} from "antd";
import "antd/dist/antd.css";

class SinifCard extends React.Component{
    constructor() {
        super();
          this.state = {
          isFlipped: false
        };
        this.handleClick = this.handleClick.bind(this);
      }
     
      handleClick(e) {
        e.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
      }

      render() {

        return (
          <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical">
            <div style={{marginTop:'2rem',marginRight:'1rem', marginLeft:'1rem', display:'grid'}} >
                <Card
                    onClick={this.handleClick}
                    actions={[]}
                    title={this.props.title}     
                    hoverable = 'true'
                    style={{width: '18rem', borderRadius: '2rem', margin: '1rem', backgroundColor:'#51F0AC'}}
                    //cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                    headStyle={{borderRadius: '2rem', backgroundColor: 'rgba(255, 255, 255, 0.4)', border: 0, textAlign:'center', height:'3rem'}}
                    bodyStyle={{border: 0, margin:'1rem', verticalAlign:'top',color:'#A33A27', fontWeight:'bold' }}
                >
                    <p>Biz sağlık hizmetleri alanında çalışan öğretmenleriz.</p>
                </Card>
            </div>
     
            <div style={{margin:'2rem', display:'grid'}}>
            <Card
                //onClick={this.handleClick}
                actions={[]}
                title={this.props.title}     
                hoverable = 'true'
                style={{width: '18rem', borderRadius: '2rem', margin: '2rem', backgroundColor:'salmon'}}
                //cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                headStyle={{borderRadius: '2rem', backgroundColor: 'rgba(255, 255, 255, 0.4)', border: 0, textAlign:'center', height:'3rem'}}
                bodyStyle={{border: 0, margin:'1rem', verticalAlign:'top' }}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={this.props.aciklama}
                    style={{textAlign:'left'}}
                    renderItem={item => (
                    //<List.Item style={{height:'2rem',color:'#A33A27'}}>
                    <List.Item>
                        <List.Item.Meta
                            //title = {item.yenimi == true ? <Badge status='processing'>Yeni {item.ad}</Badge> : item.ad}
                            title = {this.Yenimi(item)}
                        />
                    </List.Item>
                    )}
                />
               <div style={{marginTop:'3rem',textAlign:'right'}}> <Button onClick={this.handleClick} type="primary" shape="circle">x</Button></div>
            </Card>
            </div>
          </ReactCardFlip>
        )
      }

    Yenimi(item) {
        const isLoggedIn = item.yenimi;
        if (isLoggedIn) {
          return <a><Badge.Ribbon text="Yeni" size="small" style={{height:'1rem',backgroundColor:'red',borderRadius:'1em'}}>{item.ad}</Badge.Ribbon></a>;
        }
        return <a href="http://uyg.hemsire.org" target="_blank">{item.ad}</a>;
    }
}

export default SinifCard;