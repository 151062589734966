//import { Row, Col } from 'antd';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import React from 'react'
import { Link } from 'react-router-dom'

class S404 extends React.Component{
    render(){ 
        return (
            <div className="App"  style={{position:'fixed', top:'50%',left:'50%',marginTop:'-10%',marginLeft:'-30%'}}>
                <Row justify="center">
                    <Col>
                        <h1>Aradığınızı bulunamadık!</h1>
                        <h1>Yukarıdaki menüden veya anasayfadan aradığını bulabileceğinize eminiz.</h1>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>Ana Sayfaya <Link to="/">geri dön</Link></Col>
                </Row>
            </div>
        )
    }
}
export default React.memo(S404);