import Column from 'antd/lib/table/Column';
import React from 'react'
import Duyuru from '../../componentler/Duyuru'
//import { Row, Col } from "antd";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

class Anasayfa extends React.Component{
        render(){ 
        return (
        <div>
            <Row justify='center'>
                <Col><Duyuru sinif="/9" title="9. Sınıf" resim="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Flower_heart.jpg/200px-Flower_heart.jpg"/></Col>
                <Col><Duyuru sinif="/10" title="10. Sınıf" resim="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDQ2QWTDCe_mYma9pgWiJQ8C7-OAUGETHk6Q&usqp=CAU"/></Col>
                <Col><Duyuru sinif="/11" title="11. Sınıf" resim="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Flower_heart.jpg/200px-Flower_heart.jpg"/></Col>
                <Col><Duyuru sinif="/12" title="12. Sınıf" resim=""/></Col>
            </Row>
        </div>)
    }
}
export default React.memo(Anasayfa);