import React, {useEffect, useState} from 'react'
//import { a } from 'sodium-frp-react';
import axios from "axios";
import Item from 'antd/lib/list/Item';

//class Hakkimizda extends React.Component{
function Hakkimizda () {

    const [userData,setUserData] = useState([]);
/*
    useEffect(()=>{
        axios.get('https://jsonplaceholder.typicode.com/todos/')
             .then(response => {
                console.log(response.data)
                console.log(response.data.title)
                //const { data } = response.data
                setUserData(response.data)
            }).catch(error => {
                console.log(error);
            })
    },[]);
*/

useEffect(()=>{
    axios.get('https://www.saglikhizmetleri.gen.tr/sapi/dersler/TumDersler')
         .then(response => {
            console.log(response.data)
            console.log(response.data)
            //const { data } = response.data
            setUserData(response.data)
        }).catch(error => {
            console.log(error);
        })
},[]);


    const listItems = userData.map((number,key) =>
                <li key={key}>{number.DERSADI}</li>
    );

return (
        <React.Fragment>
           <div>Hakkımızda</div>
           <div>{userData.title}</div>
           <div>{userData.completed}</div>
           <div>{userData.id}</div>
           <div>{userData.userId}</div>
           <div>Hakkımızda</div>
           <div>{listItems}</div>
        </React.Fragment>
    )
}

export default React.memo(Hakkimizda);