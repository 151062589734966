import React from "react";
import './App.css';
import { Link } from 'react-router-dom'
import MenuTop from './componentler/MenuTop'
import { MailOutlined, DatabaseTwoTone, HomeTwoTone, TagTwoTone, MehTwoTone } from '@ant-design/icons';
import { Route, Redirect } from 'react-router-dom';

function App() {

  return (
        <div className="App" style={{display:'inline-list-item', gridColumnGap:'50px', justifyContent:'center',}}>
            <MenuTop aciklama ={[
              {menuKey:'anasayfa',menuAdi:<Link to="/Anasayfa">Anasayfa</Link>,menuIcon:<HomeTwoTone />},
              {menuKey:'dokuz',menuAdi:<Link to="/9">9.Sınıf Materyalleri</Link>,menuIcon:<DatabaseTwoTone />},
              {menuKey:'on',menuAdi:<Link to="/10">10.Sınıf Materyalleri</Link>,menuIcon:<DatabaseTwoTone />},
              {menuKey:'onbir', menuAdi:<Link to="/11">11.Sınıf Materyalleri</Link>,menuIcon:<DatabaseTwoTone />},
              {menuKey:'oniki',menuAdi:<Link to="/12">12.Sınıf Materyalleri</Link>,menuIcon:<DatabaseTwoTone />},
              {menuKey:'hakkimizda',menuAdi:<Link to="/Hakkimizda">Hakkımızda</Link>,menuIcon:<TagTwoTone />},
              {menuKey:'giris',menuAdi:<Link to="/Giris">Giriş</Link>,menuIcon:<MehTwoTone />}
            ]}/>
            <Route exact path="/" render={() => (
              <Redirect to="/anasayfa"/>
            )}/>

        </div>
  );
}



export default React.memo(App);
